import { GET_ALL_PRODUCTS_RESPONSE, PRODUCT_ERROR, RESET_ALL_STATE_VALUES } from "../../utils/Constant";

const INITIAL_STATE = {
    products: [],
    error: null
}

const ProductReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCTS_RESPONSE:
            return { ...state, products: action.payload }

        case PRODUCT_ERROR:
            return { ...state, error: action.payload }

        case RESET_ALL_STATE_VALUES:
            return INITIAL_STATE

    }
    return state
}

export default ProductReducer;