import React from "react";
import Newpurchase from './NewPurchase'
// import PurchaseForm from "./PurchaseForm";



function Index() {
    return(
        <>
        <Newpurchase />
        {/* <PurchaseForm /> */}
        </>
    )
}
export default Index;