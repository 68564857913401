import React, { useEffect, useState, forwardRef } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  ERROR_CODE_FAILURE,
  GENERATE_SUPPLIER_SUMMARY_PDF_API_CALL,
  RESET_CODE,
  SEARCH_CUSTOMER_BY_CUSTOMERS_ID_CALL,
  SOA_SUPPLIER_REPORT_API_CALL,
  SUCCESS_CODE_NO,
} from "../../utils/Constant";
import { useDispatch, connect } from "react-redux";
import { IoMdDownload } from "react-icons/io";
import { BeatLoader } from "react-spinners";
import moment from 'moment';
import DatePicker from "react-datepicker";

const SuplierSOAList = (props) => {
  const [selectedSuplier, setSelectedSuplier] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isPdfDownloadClicked, setIsPDFDownloadClicked] = useState(false)
  const [requestParams, setRequestParams] = useState({requestId: location.state.id, startDate: '', endDate: ''})

  
  const requestId = location.state.id;

  useEffect(() => {
    dispatch({ type: SOA_SUPPLIER_REPORT_API_CALL, payload: requestParams });
    dispatch({
      type: SEARCH_CUSTOMER_BY_CUSTOMERS_ID_CALL,
      data: requestId,
    });

  }, [requestId]);

  useEffect(() => {
    setSelectedSuplier(props.customers.selectedCustomerDetails);
  }, [props.customers.selectedCustomerDetails]);

  useEffect(() => {
    setLoading(false)
    if (props.common.successCode === SUCCESS_CODE_NO && isPdfDownloadClicked) {
      window.open(props.report.supplierSummaryPDF?.fileUrl, "_blank");
      setLoading(false)
      setIsPDFDownloadClicked(false)
    }
    dispatch({type: RESET_CODE})
  }, [props.common.successCode]);

  useEffect(() => {
    setLoading(false)
    if (isPdfDownloadClicked) {
      setIsPDFDownloadClicked(false)
    }
      if (props.common.code === ERROR_CODE_FAILURE) {
        setLoading(false)
        dispatch({ type: RESET_CODE });
        toast(props.common.errorMessage, {
          type: "error",
        });
      }
      dispatch({ type: RESET_CODE });
  }, [props.common.code])


  const handlesubmitPDF = () => {
    setIsPDFDownloadClicked(true)
    setLoading(true);
    dispatch({ type: GENERATE_SUPPLIER_SUMMARY_PDF_API_CALL, payload: requestParams })
  }

  // useEffect(()=> {
  //   if(props.report.supplierSummaryPDF?.fileUrl) {
  //     window.open(props.report.supplierSummaryPDF?.fileUrl, '_blank');
  //     setLoading(false);
  //   }
  // }, [props.report.supplierSummaryPDF])

  const handleDateChange = (dates) => {
   
    if (dates.length > 1) {
      setStartDate(dates[0])
      if (dates[1]) {
        setEndDate(dates[1])
        if (dates[1] < dates[0]) {
          setStartDate(dates[1])
          setEndDate(dates[0])
        }
      }
      else {
        setEndDate(null)
      }
    }
    else {
      setStartDate(dates[0])
      setEndDate(null)
    }
  
};

const onDateSelected = () => {
  let params = requestParams
  if (endDate) {
    // requestParams["endDate"] = moment(endDate).format("DD-MM-yyyy")
    setRequestParams({ ...requestParams,
      endDate: moment(endDate).format("DD-MM-yyyy"),
    })
    params.endDate = moment(endDate).format("DD-MM-yyyy")
  }
  if (startDate) {
    // requestParams["startDate"] = moment(startDate).format("DD-MM-yyyy")
    setRequestParams({ ...requestParams,
      startDate: moment(startDate).format("DD-MM-yyyy"),
    })
    params.startDate = moment(startDate).format("DD-MM-yyyy")
  }

  if (startDate) {
    setLoading(true)
    dispatch({ type: SOA_SUPPLIER_REPORT_API_CALL, payload: params });
  }
}

const onClearDate = () => {
  setRequestParams({ ...requestParams,
    endDate: "",
    startDate: "",
  })
  setStartDate(null)
  setEndDate(null)
  setLoading(true)
  let params = requestParams
  params.startDate = ""
  params.endDate = ""
  dispatch({ type: SOA_SUPPLIER_REPORT_API_CALL, payload: requestParams});
}

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{display: 'flex', backgroundColor: '#D6D6D6', paddingRight: 10, alignItems: 'center', borderRadius: 5}}>
    <button  style={{paddingLeft: 20, paddingRight: 20, backgroundColor: '#D6D6D6', borderWidth: 0, paddingTop: 8, paddingBottom: 8, color: '#1d1d5e', fontWeight: '600', borderRadius: 5 }} onClick={onClick} ref={ref}>
      {value ? value : 'Search with date'} 
    </button>
    <div style={{backgroundColor: '#1d1d5e', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 8, width: 16, height: 16, padding: 5, cursor: 'pointer'}} onClick={onClearDate}>
      <label style={{fontWeight: 'bold',  fontSize: 10, color: '#D6D6D6', cursor: 'pointer'}}>X</label>
    </div>
    </div>
  ));

  return (
    <>
    <ToastContainer />
     <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
      <div style={{ paddingLeft: 50, paddingRight: 50, marginTop: 75 }}>
        <Container fluid>
          <Row>
            <Col
              style={{
                fontSize: 16,
                fontWeight: "500",
                color: "#6E6A6A",
                textDecoration: "underline",
              }}
            >
              Statement of Accounts - Supplier
            </Col>
            <Col className="col-6 text-end">
              {selectedSuplier ? (
                <p key={selectedSuplier.id}>
                  <strong>{selectedSuplier.name}</strong>
                  <br />
                  {selectedSuplier.addresses &&
                  selectedSuplier.addresses.length > 0 ? (
                    <>
                      <small>
                        {selectedSuplier.addresses[0]?.addressLine1}
                      </small>
                      ,<br />
                      <small>{selectedSuplier.addresses[0]?.city}</small>,
                      <small>{selectedSuplier.addresses[0]?.countryName}</small>
                      {/* <br /><small>{selectedSuplier.addresses[0]?.zipcode}</small> */}
                    </>
                  ) : (
                    <small></small>
                  )}
                  <br />
                  <small>{selectedSuplier.mobile}</small>
                </p>
              ) : (
                <p>NA</p>
              )}
            </Col>
          </Row>


          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 20, marginTop: 25}}>
          <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              onCalendarClose={onDateSelected}
              startDate={startDate}
              isClearable={false}
              endDate={endDate}
              dateFormat={"dd/MM/yyyy"}
              placeholderText="Select Date Range"
              selectsRange
              customInput={<DatePickerInput />}
            />
          </div>

          <div
            className="mt-3 p-1"
            style={{ overflowY: "scroll", minHeight: 380, maxHeight: 500 }}
          >
            <Table size="sm" striped hover bordered>
              <thead style={{}}>
                <tr>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    DATE
                  </th>
                  <th className="text-start ps-4" style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    SUPPLIER NAME
                  </th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    ORDER NO
                  </th>
                  {/* <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    DESCRIPTION
                  </th> */}
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    DR(AED)
                  </th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    CR(AED)
                  </th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    BALANCE(AED)
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={3}
                    className="text-start ms-3 gap-3"
                    style={{
                      color: "#6E6A6A",
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                  >
                    OPENING BALANCE AS ON STARTING DATE
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.report.soaSupplierList &&
                  props.report.soaSupplierList.map((item) => (
                    <tr key={item.id}>
                      <td>{item.creditedDate}</td>
                      <td className="text-start ps-4">{item.name}</td>
                      <td>{item.orderId}</td>
                      <td>
                        {item.credited.toString() === "false"
                          ? item.amount
                          : "-"}
                      </td>
                      <td>
                        {item.credited.toString() === "true"
                          ? item.amount
                          : "-"}
                      </td>
                      <td>{item.outStandingAmount}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div
            className="mt-2 text-end me-3 fs-5"
            style={{
              textDecoration: "underline",
              lineHeight: 1.4,
              letterSpacing: -0.7,
              cursor: "pointer",
              textUnderlineOffset: 4,
            }}
          >
            <u onClick={handlesubmitPDF}>
              {" "}
              <IoMdDownload
                style={{
                  fontSize: 20,
                }}
              />{" "}
              E- Statement of Supplier
            </u>
          </div>
        </Container>
      </div>
      {loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              top: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // display: "flex",
            }}
          >
            <div
              style={{
                backgroundColor: "#EEEEEE33",
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BeatLoader color="#1d1d5e" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    customers: state.customers,
    report: state.report,
    common: state.commonReducer,
  };
};

export default connect(mapStateToProps)(SuplierSOAList);
