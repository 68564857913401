import React, { useState, useEffect } from "react";
import { Row, Col, Form, Table, Button } from "react-bootstrap";
import { useDispatch, connect } from "react-redux";
import CreditDebitTableBody from "./CreditDebitTableBody";
import DebitNotesTableBody from "./DebitNotesTableBody";
import { RESET_CODE, SUCCESS_CODE_NO, ERROR_CODE_FAILURE, ADD_DEBIT_NOTE_API_CALL } from "../../utils/Constant";
import { BeatLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { CreditDebit } from '../../Assets/images/creditdebit.svg'
import { useNavigate } from "react-router-dom";

const AddCreditDebitNotes = (props) => {

    console.log(props);
    const [loading, setLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [selectedId, setSelectedId] = useState()
    const [debitAmount, setDebitAmount] = useState()
    const [debitReason, setDebitReason] = useState()
    const dispatch = useDispatch()
    const navigation = useNavigate();

    useEffect(() => {
        setLoading(false)
        if (props.common.successCode === SUCCESS_CODE_NO) {
            setLoading(false)
            dispatch({ type: RESET_CODE });
            toast("Added successfully", {
                type: "success",
            });
            navigation(-1)
        }
    }, [props.common.successCode])

    useEffect(() => {
        setLoading(false)
        if (props.common.code === ERROR_CODE_FAILURE) {
            setLoading(false)
            dispatch({ type: RESET_CODE });
            toast(props.common.errorMessage, {
                type: 'error',
            });
        }
    }, [props.common.code])

    const showLoader = () => {
        setLoading(true)
    }

    const updateError = (message) => {
        setShowError(true)
        setErrorMessage(message)

        setTimeout(() => {
            setShowError(false)
        }, [2000])
    }

    const updateIdSelection = (id) => {
        setSelectedId(id)
    }

    const onDebitChange = (e) => {
        setDebitAmount(e.target.value)
    }

    const onDebitReasonChange = (e) => {
        setDebitReason(e.target.value)
    }

    const addDebitApiCall = () => {

        if (debitAmount <= 0 || isNaN(debitAmount)) {
            updateError("Invalid debit amount")
            return
        }
        if (debitReason === undefined || debitReason === '' || debitReason === null) {
            updateError("Add the reason")
            return
        }
        const params = {
            createdBy: props.loggedInUser.loginId,
            customerType: 1,
            finalAmount: debitAmount,
            invoiceId: props.invoice.invoiceDetails.invoiceOrderId,
            reason: debitReason,
            type: 1
        }
        dispatch({type: ADD_DEBIT_NOTE_API_CALL, payload: params })
    }

    return <div style={{ paddingTop: 75, paddingLeft: 50, paddingRight: 50, paddingBottom: 75, }}>
        <ToastContainer position="top-right" autoClose={3000} />
        <Row className="mt-3">
            <Col className="col-4">
                <div
                    style={{
                        backgroundColor: "#f0f0f0",
                        padding: "8px",
                        width: 300,
                        height: "auto",
                        borderRadius: 5,
                    }}
                >
                    <p>
                        <strong style={{ fontSize: 12 }}>Bill From:</strong> <br />
                        <strong style={{ fontSize: 14 }}>H&T HOLIDAYS</strong> <br />
                        <small style={{ fontSize: 12 }}>Tours & Travels</small> <br />
                        <small style={{ fontSize: 12 }}>
                            Building No.10 AlNahyan Camp
                        </small>
                        <br />
                        <small style={{ fontSize: 12 }}>
                            Near Executive Suites, Abu Dhabi
                        </small>
                    </p>
                </div>
            </Col>
            <Col className="col-4 d-flex justify-content-center">
                <p className="p-3 rounded-2" style={{ backgroundColor: '#f0f0f0', width: 250 }}>
                    <strong>{props.invoice.invoiceDetails.customerName}</strong> <br />
                    <small style={{ fontSize: 13 }}>{props.invoice.invoiceDetails.addressLine1}</small>, <br />
                    <small style={{ fontSize: 13 }}>{props.invoice.invoiceDetails.city}</small>, <br />
                    <small style={{ fontSize: 13 }}>{props.invoice.invoiceDetails.country}</small>
                </p>
            </Col>
            <Col className="col-4 text-end fw-bolder">Invoice No: {props.invoice.invoiceDetails.invoiceOrderId}</Col>
        </Row>

        <Row className="mt-4 mb-3">
            <Col className="col-7 d-flex justify-content-start">
                <Form.Group>
                    <Form.Label style={{ fontSize: 14, fontWeight: "500" }}>
                        Invoice Date
                    </Form.Label>
                    <Form.Control
                        className="inputfocus rounded-0"
                        style={{ height: "30px", fontSize: 14 }}
                        // type="date"
                        value={props.invoice.invoiceDetails.invoiceDate}
                        readOnly
                    />
                </Form.Group>
                <Form.Group className="ms-2">
                    <Form.Label style={{ fontSize: 14, fontWeight: "500" }}>
                        Due Date
                    </Form.Label>
                    <Form.Control
                        className="inputfocus rounded-0"
                        style={{ height: "30px", fontSize: 14 }}
                        // type="date"
                        value={props.invoice.invoiceDetails.dateDueDate}
                        readOnly
                    />
                </Form.Group>
                <Form.Group className="ms-2">
                    <Form.Label style={{ fontSize: 14, fontWeight: "500" }}>
                        Net *
                    </Form.Label>
                    <Form.Control
                        className="inputfocus rounded-0"
                        style={{ width: 175, height: "30px", fontSize: 14 }}
                        readOnly
                        value={`Net ${props.invoice.invoiceDetails.net}`}
                    ></Form.Control>
                </Form.Group>
                <Form.Group className="ms-2">
                    <Form.Label style={{ fontSize: 14, fontWeight: "500" }}>
                        Sales Person
                    </Form.Label>
                    <Form.Control
                        className="inputfocus rounded-0"
                        style={{ height: "30px", fontSize: 14 }}
                        readOnly
                        value={props.invoice.invoiceDetails.createdBy}
                    />
                </Form.Group>
            </Col>
        </Row>

        <label style={{ fontSize: 18, color: '#1d1d5e', fontWeight: '500', paddingBottom: 10 }}>Add Credit Notes</label>

        <div>
            <Table striped hover size="sm" bordered>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Product</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Quantity</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Price</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Discount %</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>VAT %</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Total Amount</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Reason</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.invoice.invoiceDetails && props.invoice.invoiceDetails.listProducts && props.invoice.invoiceDetails.listProducts.length > 0 ? (
                            props.invoice.invoiceDetails.listProducts.map(item => {
                                return !item.hasCreditDebitNotes && <CreditDebitTableBody item={item} showLoader={showLoader} updateError={updateError} selectedId={selectedId} updateIdSelection={updateIdSelection} />
                            })
                        ) : <label style={{ color: 'red' }}>No data to show</label>
                    }
                    <tr>

                    </tr>
                </tbody>
            </Table>
        </div>

        <div style={{ marginTop: 20 }}>
            <label style={{ fontSize: 18, color: '#1d1d5e', fontWeight: '500', paddingBottom: 10 }}>Debit Notes</label>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    !props.invoice.invoiceDetails.hasDebitNotes ?  <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Control
                            type="integer"
                            style={{ width: 200, height: "30px" }}
                            placeholder="Enter debit notes amount"
                            value={debitAmount}
                            onChange={onDebitChange}
                        />
    
                        <Form.Control
                            type="string"
                            style={{ width: 200, height: "30px", marginLeft: 15 }}
                            placeholder="Enter the reason"
                            value={debitReason}
                            onChange={onDebitReasonChange}
                        />
                    </div>
    
                    <div style={{marginTop: 12, marginBottom: 15}}>
                        <Button
                            style={{
                                backgroundColor: "#1d1d5e",
                                borderColor: "#1d1d5e",
                                fontSize: 10,
                                fontWeight: 700,
                                marginLeft: 5
                            }}
    
                            onClick={addDebitApiCall}
                        >
                            Done
                        </Button>
                    </div>
                    </div> : <div>
                            <label>Debit Note: {props.invoice.invoiceDetails.debitNotesReason} - {props.invoice.invoiceDetails.debitNotesAmount}AED</label>
                        </div>
                }
               
                

            </div>
            <Table striped hover size="sm" bordered style={{display: 'none'}}>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Product</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Quantity</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Price</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Discount %</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>VAT %</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Total Amount</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Reason</th>
                        <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.invoice.invoiceDetails && props.invoice.invoiceDetails.listProducts && props.invoice.invoiceDetails.listProducts.length > 0 ? (
                            props.invoice.invoiceDetails.listProducts.map(item => {
                                return !item.hasCreditDebitNotes && <DebitNotesTableBody item={item} showLoader={showLoader} updateError={updateError} selectedId={selectedId} updateIdSelection={updateIdSelection} />
                            })
                        ) : <label style={{ color: 'red' }}>No data to show</label>
                    }
                    <tr>

                    </tr>
                </tbody>
            </Table>
        </div>

        {loading && (
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100vh",
                    top: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#EEEEEE33",
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <BeatLoader color="#1d1d5e" />
                </div>
            </div>
        )}

        {
            showError && <div style={{ position: 'absolute', top: 60, backgroundColor: '#f21b30', left: '45%', paddingLeft: 25, paddingRight: 25, paddingTop: 5, paddingBottom: 5, borderRadius: 8, }}>
                <label style={{ color: '#FFFFFF', fontSize: 14, fontWeight: '500' }}>{errorMessage}</label>
            </div>
        }

    </div>
}

const mapsToProps = (state) => {
    return {
        invoice: state.invoice,
        common: state.commonReducer,
        loggedInUser: state.users,
    }
}

export default connect(mapsToProps)(AddCreditDebitNotes);