import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { GET_INVOICES_BASED_ON_PRODUCT_TYPES_API_CALL } from "../../utils/Constant";
import { useLocation } from "react-router-dom";

const ProductView = (props) => {

  const dispatch = useDispatch();

  const location = useLocation()

  useEffect(()=> {
    dispatch({type: GET_INVOICES_BASED_ON_PRODUCT_TYPES_API_CALL, payload:{requestId: location.state.id } })
  }, [location.state.id]);

  console.log(props.report.productTypesInvoices);

  return (
    <>
      <div style={{ paddingRight: 50, paddingLeft: 50, marginTop: 75 }}>
        <Container fluid>
            <Row>
                <Col style={{fontSize:16, fontWeight:"500", color:"#6E6A6A", textDecoration:"underline"}}>PRODUCT TYPE BASED</Col>
            </Row>

            <h6 className="mt-4 fw-bolder" style={{color:"#1d1d5e"}}>Invoice List</h6>

            <div className="" style={{overflowY:"scroll", minHeight:100, maxHeight:350}}>
                <Table size="sm" striped hover bordered>
                    <thead>
                        <tr>
                            <th style={{backgroundColor:"#1d1d5e", color:"white"}}>Business Type</th>
                            <th style={{backgroundColor:"#1d1d5e", color:"white"}}>Invoice Order</th>
                            <th style={{backgroundColor:"#1d1d5e", color:"white"}}>Generated By</th>
                            <th className="text-start" style={{backgroundColor:"#1d1d5e", color:"white", paddingLeft: '3%'}}>Customer Name</th>
                            <th className="text-start" style={{backgroundColor:"#1d1d5e", color:"white", paddingLeft: '3%'}}>Product Name</th>
                            <th className="text-start" style={{backgroundColor:"#1d1d5e", color:"white", paddingLeft: '3%'}}>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                      {props.report.productTypesInvoices.listInvoices ? props.report.productTypesInvoices.listInvoices.map((item)=>{
                        return <tr>
                          <td>{item.businessType}</td>
                          <td>{item.invoiceOrderId}</td>
                          <td>{item.createdBy ? item.createdBy : "NA"}</td>
                          <td className="text-start" style={{ paddingLeft: '3%' }}>{item.customerName}</td>
                          <td className="text-start" style={{ paddingLeft: '3%' }}>{item.productName}</td>
                          <td className="text-start" style={{ paddingLeft: '3%' }}>{item.totalAmount}</td>
                        </tr>
                      }) : (
                        <tr>
                          <td className="fst-italic" style={{ color: "red" }} colSpan={6}>No data available in the table.</td>
                        </tr>
                      )}

                    </tbody>
                </Table>
            </div>

            <h6 className="mt-4 fw-bolder" style={{color:"#1d1d5e"}}>Purchase List</h6>          

            <div className="mb-5" style={{overflowY:"scroll", minHeight:100, maxHeight:350}}>
                <Table size="sm" striped hover bordered>
                    <thead>
                        <tr>
                            <th style={{backgroundColor:"#1d1d5e", color:"white"}}>Buiness Type</th>
                            <th style={{backgroundColor:"#1d1d5e", color:"white"}}>Purchase Order</th>
                            <th style={{backgroundColor:"#1d1d5e", color:"white"}}>Generated By</th>
                            <th className="text-start" style={{backgroundColor:"#1d1d5e", color:"white", paddingLeft: '2%'}}>Customer Name</th>
                            <th className="text-start" style={{backgroundColor:"#1d1d5e", color:"white", paddingLeft: '2%'}}>Product Name</th>
                            <th className="text-start" style={{backgroundColor:"#1d1d5e", color:"white", paddingLeft: '2%'}}>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                      {props.report.productTypesInvoices.listPurchaseOrders? props.report.productTypesInvoices.listPurchaseOrders.map((item)=>{
                        return <tr>
                          <td>{item.businessType}</td>
                          <td>{item.invoiceOrderId}</td>
                          <td>{item.createdBy ? item.createdBy : "NA"}</td>
                          <td className="text-start" style={{paddingLeft: '2%'}}>{item.customerName}</td>
                          <td className="text-start" style={{paddingLeft: '2%'}}>{item.productName}</td>
                          <td className="text-start" style={{paddingLeft: '2%'}}>{item.totalAmount}</td>
                        </tr>
                      }) : (
                        <tr>
                          <td className="fst-italic" style={{ color: "red" }} colSpan={6}>No data available in the table.</td>
                        </tr>
                      )}

                    </tbody>
                </Table>
            </div>

        </Container>
      </div>
    </>
  );
};

const mapsToProps = (state) => {
  return {
    report: state.report,
  }
}

export default connect(mapsToProps)(ProductView);
