import React, { useEffect, useState } from "react";

const ExpenseItemList = (props) => {

    const [total, setTotalAmount] = useState(0)
    useEffect(() => {
        const total = props.item?.listSubCategories?.reduce(function(total, item={totalAmount: 0}) {
            return typeof(total)== "number"?  total + item.totalAmount : total.totalAmount + item.totalAmount;
        })
        setTotalAmount(total)
    }, [])

    return  <div key={props.item.id} style={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ backgroundColor: '#1d1d5e', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
        <label style={{ color: '#FFFFFF', fontSize: 14 }}>{props.item.catName}</label>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#EBEBEB' }}>
        {/* paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, */}
        {
            props.item.listSubCategories.map((subCatItems) => {
                return subCatItems.totalAmount != 0 && <div key={subCatItems.subCatId}>
                    <div key={subCatItems.subCatId} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <label style={{ fontSize: 15, marginTop: 7, marginBottom: 7, marginLeft: 20, fontWeight: 'bold' }}>{subCatItems.subCateName}</label>
                        <label style={{ fontSize: 10, marginTop: 7, marginBottom: 7, marginLeft: 5, fontWeight: 'bold' }}>Total Spends: {subCatItems.totalAmount} AED</label>
                    </div>

                    <div style={{ backgroundColor: '#FFFFFF', }}>
                        <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#F8F8F8', paddingLeft: 40, paddingRight: 40, paddingTop: 5, paddingBottom: 5, }}>
                            <div style={{ flex: 1 }}>
                                <label style={{ fontSize: 15 }}>Date</label>
                            </div>
                            <div style={{ flex: 1 }}>
                                <label style={{ fontSize: 15 }}>Description</label>
                            </div>
                            <div style={{ flex: 1 }}>
                                <label style={{ fontSize: 15 }}>Amount</label>
                            </div>
                            <div style={{ flex: 4 }}>

                            </div>
                        </div>
                        {
                            subCatItems?.listExpenseModel?.map((expense) => {
                                return <div key={expense.id} style={{ display: 'flex', flexDirection: 'row', paddingLeft: 40, paddingRight: 40, }}>
                                    <div style={{ flex: 1, paddingTop: 5, paddingBottom: 5 }}>
                                        <label>{expense.date}</label>
                                    </div>
                                    <div style={{ flex: 1, paddingTop: 5, paddingBottom: 5 }}>
                                        <label>{expense.description ? expense.description : 'NA'}</label>
                                    </div>
                                    <div style={{ flex: 1, paddingTop: 5, paddingBottom: 5 }}>
                                        <label>{expense.amount} AED</label>
                                    </div>
                                    <div style={{ flex: 4 }}></div>
                                </div>
                            })
                        }
                    </div>
                </div>

            })
        }
    </div>
</div>
}

export default ExpenseItemList;