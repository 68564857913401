import { useEffect, useState, forwardRef } from "react";
import { Container, Table } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import moment from 'moment';
import DatePicker from "react-datepicker";
import { BeatLoader } from "react-spinners";
import ExpenseItemList from "./ExpenseItemList";
import { GET_EXPENSE_REPORT_API_CALL, DOWNLOAD_REPORT_PDF_API_CALL, SUCCESS_CODE_NO, RESET_CODE, ERROR_CODE_FAILURE } from "../../utils/Constant";

const ExpenseReport = (props) => {
    const dispatch = useDispatch()
    console.log(props)

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isPdfDownloadClicked, setIsPdfDownloadClicked] = useState(false)


    useEffect(() => {
        setLoading(true)
        dispatch({ type: GET_EXPENSE_REPORT_API_CALL, payload: {} })
    }, [])

    useEffect(() => {
        if (props.common.successCode === SUCCESS_CODE_NO && isPdfDownloadClicked) {
            window.open(props?.report?.expenseReport?.fileUrl, "_blank");

            setIsPdfDownloadClicked(false)

        }
        setLoading(false);
        dispatch({ type: RESET_CODE });
    }, [props.common.successCode]);

    useEffect(() => {
        if (props.common.code === ERROR_CODE_FAILURE && !isPdfDownloadClicked) { 
            setLoading(false)
        }
    }, [props.common.code])

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <div style={{ display: 'flex', backgroundColor: '#D6D6D6', paddingRight: 10, alignItems: 'center', borderRadius: 5 }}>
            <button style={{ paddingLeft: 20, paddingRight: 20, backgroundColor: '#D6D6D6', borderWidth: 0, paddingTop: 8, paddingBottom: 8, color: '#1d1d5e', fontWeight: '600', borderRadius: 5 }} onClick={onClick} ref={ref}>
                {value ? value : 'Search with date'}
            </button>
            <div style={{ backgroundColor: '#1d1d5e', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 8, width: 16, height: 16, padding: 5, cursor: 'pointer' }} onClick={onClearDate}>
                <label style={{ fontWeight: 'bold', fontSize: 10, color: '#D6D6D6', cursor: 'pointer' }}>X</label>
            </div>
        </div>
    ));

    const handleDateChange = (dates) => {

        if (dates.length > 1) {
            setStartDate(dates[0])
            if (dates[1]) {
                setEndDate(dates[1])
                if (dates[1] < dates[0]) {
                    setStartDate(dates[1])
                    setEndDate(dates[0])
                }
            }
            else {
                setEndDate(null)
            }
        }
        else {
            setStartDate(dates[0])
            setEndDate(null)
        }

    };

    const onDateSelected = () => {
        const dateParams = {}
        if (endDate) {
            dateParams["endDate"] = moment(endDate).format("DD-MM-yyyy")
        }
        if (startDate) {
            dateParams["startDate"] = moment(startDate).format("DD-MM-yyyy")
        }

        if (startDate) {
            setLoading(true)
            dispatch({ type: GET_EXPENSE_REPORT_API_CALL, payload: dateParams });
        }
    }

    const onClearDate = () => {
        setStartDate(null)
        setEndDate(null)
        setLoading(true)
        dispatch({ type: GET_EXPENSE_REPORT_API_CALL, payload: {} });
    }

    const downloadPDF = () => {
        const dateParams = {}
        if (endDate) {
            dateParams["endDate"] = moment(endDate).format("DD-MM-yyyy")
        }
        if (startDate) {
            dateParams["startDate"] = moment(startDate).format("DD-MM-yyyy")
        }
        setLoading(true)
        setIsPdfDownloadClicked(true)
        dispatch({ type: DOWNLOAD_REPORT_PDF_API_CALL, payload: dateParams })

    }

    return (
        <>
            <div style={{ marginTop: 75, paddingBottom: 50 }}>
                <Container fluid className="mt-2">
                    <div style={{ paddingLeft: 50, paddingRight: 50, }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15, paddingBottom: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                <h3 style={{ color: '#1d1d5e', fontSize: 18, marginBottom: 0 }}>Expense Report</h3>
                                {
                                    props.report.expenseReport.startDate && <p style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 10, marginBlockStart: 0, marginBlockEnd: 0 }}>{`Showing From ${props.report.expenseReport.startDate} to ${props.report.expenseReport.endDate}`}</p>
                                }
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 {
                                    props?.report?.expenseReport?.listExpenses?.length > 0 ? <div style={{ cursor: 'pointer', paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, marginRight: 12, borderWidth: 2, borderColor: '#1d1d5e', borderStyle: 'solid', borderRadius: 5 }} onClick={downloadPDF}>
                                    <label style={{ cursor: 'pointer', color: '#1d1d5e', fontWeight: 500, fontSize: 13 }}>Download Report</label>
                                </div> : null
                                 }
                                
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    onCalendarClose={onDateSelected}
                                    startDate={startDate}
                                    isClearable={false}
                                    endDate={endDate}
                                    dateFormat={"dd/MM/yyyy"}
                                    placeholderText="Select Date Range"
                                    selectsRange
                                    customInput={<DatePickerInput />}
                                />
                            </div>
                        </div>

                        <div>
                            { props.report.expenseReport?.listExpenses?.length > 0 ? props.report.expenseReport?.listExpenses?.map((item) => {
                                    return <ExpenseItemList key={item.id} item={item} />
                                }) : 
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <label style={{fontSize: 20, }}>No Expenses found</label>
                                </div>
                            }
                        </div>

                    </div>

                </Container>

                {loading && (
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100vh",
                            top: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#EEEEEE33",
                                width: "100%",
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <BeatLoader color="#1d1d5e" />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        common: state.commonReducer,
        report: state.report,
    }
}

export default connect(mapStateToProps)(ExpenseReport)