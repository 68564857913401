import { GET_ALL_RECEIPT_API_RESPONSE, GET_ALL_ORDERS_SUMMARY_API_RESPONSE, GENERATE_RECEIPT_PDF_API_RESPONSE, RESET_RECEIPT_LISTS, RESET_ALL_STATE_VALUES } from "../../utils/Constant";

const INITIAL_STATE = {
    listAllReceipt: [],
    fileurl: '',
    code: 0,
    createdByList: [],
    totalAmount: 0,
    startDate: '',
    endDate: '',
    paymentType: []
}

const ReceiptReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_RECEIPT_API_RESPONSE: {
            return { ...state, listAllReceipt: action.payload.receipts, createdByList: action.payload.createdByUsers, totalAmount: action.payload.totalAmount, endDate: action.payload.endDate, startDate: action.payload.startDate, paymentType: action.payload.paidByList }
        }
        case GENERATE_RECEIPT_PDF_API_RESPONSE:
            return { ...state, fileurl: action.payload.fileUrl, code: 0 }
            
        case RESET_RECEIPT_LISTS: {
            return { ...state, listAllReceipt: [] }
            
        }

        case RESET_ALL_STATE_VALUES:
            return INITIAL_STATE
    }

    return state;
}

export default ReceiptReducer;