import { takeEvery, call, put } from "redux-saga/effects";
import { MASTER_API_CALL, MASTER_API_RESPONSE, GET_ALL_COUNTRIES_API_REQUEST, GET_ALL_COUNTRIES_API_RESPONSE, ADD_COUNTRIES_API_REQUEST, SUCCESS_CODE, ERROR_CODE } from "../utils/Constant";
import { MasterApiCall, CountriesApiCall, AddCountry } from "../Reducer/Action/MasterAction";

function* callMasterApi() {
    const response = yield call(MasterApiCall)

    if (response.status === 200) {
        if (response.data.code === 200) {
            yield put({type: MASTER_API_RESPONSE, payload: response.data.data})
        }
       
    }
}

function* GetAllCountriesApiRequest() {
    const response = yield call(CountriesApiCall)

    if (response.status === 200) {
        if (response.data.code === 200) {
            yield put({type: GET_ALL_COUNTRIES_API_RESPONSE, payload: response.data})
        }
    }
}

function* AddCountriesApiCall(data){
    const response = yield call(AddCountry, data.payload)

    console.log(response)
    if (response.status === 200) {
        if (response.data.code === 200) {
            yield put({ type: SUCCESS_CODE })
        }
        else {
            console.log("else case")
            yield put({ type: ERROR_CODE, payload: response.data })
        }
    }
}

function* MasterSaga() {
    yield takeEvery(MASTER_API_CALL, callMasterApi)
    yield takeEvery(GET_ALL_COUNTRIES_API_REQUEST, GetAllCountriesApiRequest)
    yield takeEvery(ADD_COUNTRIES_API_REQUEST, AddCountriesApiCall)
}

export default MasterSaga;