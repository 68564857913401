import axios from "axios";

// baseURL: process.env.REACT_APP_BASE_URL,
const AxiosConfig = axios.create({
        baseURL: 'http://handt.llca.in/',
        headers: {
            "Content-Type": "application/json",
        }
    })

export {AxiosConfig};

// https://handt-apis.llca.in/ - main apiURL
// http://handt.llca.in/ - Test apiURL