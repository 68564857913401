import { act } from "react-dom/test-utils";
import {
  CUSTOMER_INVOICE_SUMMARY_API_RESPONSE,
  GET_ALL_ORDERS_SUMMARY_API_RESPONSE,
  GET_SUPPLIER_PAYMENT_REPORT_API_REPONSE,
  GET_PRODUCT_TYPE_SUMMARY_REPORT_API_REPOSNE,
  GET_INVOICES_BASED_ON_PRODUCT_TYPES_API_RESPONSE,
  SOA_CUSTOMER_REPORT_API_RESPONSE,
  SOA_SUPPLIER_REPORT_API_RESPONSE,
  PDF_VIEW_CUSTOMER_API_RESPONSE,
  GENERATE_SUPPLIER_SUMMARY_PDF_API_RESPONSE,
  GET_EXPENSE_REPORT_API_RESPONSE,
  RESET_ALL_STATE_VALUES,
  RESET_SUPPLIER_REPORT_LISTS,
  RESET_CUSTOMER_REPORT_LISTS,
  DOWNLOAD_REPORT_PDF_API_RESPONSE, 
  RESET_EXPENSE_RESPORT_LIST
} from "../../utils/Constant";

const INITIAL_STATE = {
  customersInvoiceList: [],
  reportSummaryList: [],
  supplierPaymentReportList: [],
  productTypeBasedSummary: [],
  productTypesInvoices: { listInvoices: [], listPurchaseOrders: [] },
  soaSupplierList: [],
  soaCustomerList: [],
  pdfcustomerView: [],
  supplierSummaryPDF: [],
  expenseReport: {
    fileUrl: ''
  },
  expense: null
};

const ReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_INVOICE_SUMMARY_API_RESPONSE:
      return { ...state, customersInvoiceList: action.payload };

    case GET_ALL_ORDERS_SUMMARY_API_RESPONSE:
      return { ...state, reportSummaryList: action.payload };

    case GET_SUPPLIER_PAYMENT_REPORT_API_REPONSE:
      return { ...state, supplierPaymentReportList: action.payload };

    case GET_PRODUCT_TYPE_SUMMARY_REPORT_API_REPOSNE:
      return { ...state, productTypeBasedSummary: action.payload };

    case GET_INVOICES_BASED_ON_PRODUCT_TYPES_API_RESPONSE:
      return {
        ...state,
        productTypesInvoices: {
          listInvoices: action.payload.listInvoices,
          listPurchaseOrders: action.payload.listPurchaseOrders,
        },
      };

    case SOA_SUPPLIER_REPORT_API_RESPONSE:
      return { ...state, soaSupplierList: action.payload,  };

    case SOA_CUSTOMER_REPORT_API_RESPONSE:
      return { ...state, soaCustomerList: action.payload, };

    case PDF_VIEW_CUSTOMER_API_RESPONSE:
      return { ...state, pdfcustomerView: action.payload, };

    case GENERATE_SUPPLIER_SUMMARY_PDF_API_RESPONSE:
      return { ...state, supplierSummaryPDF: action.payload, };

    case GET_EXPENSE_REPORT_API_RESPONSE:
      return { ...state, expenseReport: action.payload };

    case DOWNLOAD_REPORT_PDF_API_RESPONSE:
      let expenseReport = state.expenseReport
      expenseReport.fileUrl = action.payload.data.fileUrl
      return {...state, expenseReport: expenseReport}

    case RESET_SUPPLIER_REPORT_LISTS:
      return {...state, soaSupplierList: []}

      case RESET_EXPENSE_RESPORT_LIST:
        return {...state, expenseReport: {
          listExpenses: []
        }}

      case RESET_CUSTOMER_REPORT_LISTS:
        return {...state, soaCustomerList: []}

    case RESET_ALL_STATE_VALUES:
      return INITIAL_STATE

  }
  return state;
};

export default ReportReducer;
