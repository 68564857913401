import React, { useEffect, useState, forwardRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { Container, Row, Col, Table } from "react-bootstrap";
import moment from 'moment';
import { useLocation } from "react-router-dom";
import {
  SEARCH_CUSTOMER_BY_CUSTOMERS_ID_CALL,
  SOA_CUSTOMER_REPORT_API_CALL,
  SOA_CUSTOMER_REPORT_API_RESPONSE,
  PDF_VIEW_CUSTOMER_API_CALL,
  SUCCESS_CODE_NO,
  RESET_CODE,
  ERROR_CODE_FAILURE
} from "../../utils/Constant";
import { useDispatch, connect } from "react-redux";
import { IoMdDownload } from "react-icons/io";
import { BeatLoader } from "react-spinners";

const CustomerSOAList = (props) => {

  const [soaCustomer, setSoaCustomer] = useState([]);
  const [SelectedCustomer, setSelectedCustomer] = useState([]);
  const [isPdfDownloadClicked, setIsPdfDownloadClicked] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading,setLoading] = useState(false);
  const [requestParams, setRequestParams] = useState({requestId: location.state.id, startDate: '', endDate: ''})

  // soa customer api dispatch
  useEffect(() => {
    dispatch({ type: SOA_CUSTOMER_REPORT_API_CALL, payload: requestParams });
  }, [location.state.id]);

  useEffect(() => {
    setSoaCustomer(props.report.soaCustomerList);
  }, [props.report.soaCustomerList]);

  // dispatch for the customer search
  useEffect(() => {
    dispatch({
      type: SEARCH_CUSTOMER_BY_CUSTOMERS_ID_CALL,
      data: requestParams.requestId,
    });
  }, [location.state.id]);

  useEffect(() => {
    setSelectedCustomer(props.customers.selectedCustomerDetails);
  }, [props.customers.selectedCustomerDetails]);

  // dispatch for the customer summary handle submit pdf
  const handlesubmitpdfgenerate = () => {
    setLoading(true)
    setIsPdfDownloadClicked(true)
    dispatch({ type: PDF_VIEW_CUSTOMER_API_CALL, payload: requestParams });
  };

  useEffect(() => {
    setLoading(false)
    if (props.common.successCode === SUCCESS_CODE_NO && isPdfDownloadClicked) {

      window.open(props.report.pdfcustomerView?.fileUrl, "_blank");
      setLoading(false)
      setIsPdfDownloadClicked(false)
    }
    dispatch({type: RESET_CODE})
  }, [props.common.successCode]);

  useEffect(() => {
    setLoading(false)
    if (isPdfDownloadClicked) {
      setIsPdfDownloadClicked(false)
    }
      if (props.common.code === ERROR_CODE_FAILURE) {
        setLoading(false)
        dispatch({ type: RESET_CODE });
        toast(props.common.errorMessage, {
          type: "error",
        });
      }
  }, [props.common.code])
  
  const handleDateChange = (dates) => {
   
    if (dates.length > 1) {
      setStartDate(dates[0])
      if (dates[1]) {
        setEndDate(dates[1])
        if (dates[1] < dates[0]) {
          setStartDate(dates[1])
          setEndDate(dates[0])
        }
      }
      else {
        setEndDate(null)
      }
    }
    else {
      setStartDate(dates[0])
      setEndDate(null)
    }
  
};

const onDateSelected = () => {
  let params = requestParams
  if (endDate) {
    // requestParams["endDate"] = moment(endDate).format("DD-MM-yyyy")
    setRequestParams({ ...requestParams,
      endDate: moment(endDate).format("DD-MM-yyyy"),
    })
    params.endDate = moment(endDate).format("DD-MM-yyyy")
  }
  if (startDate) {
    // requestParams["startDate"] = moment(startDate).format("DD-MM-yyyy")
    setRequestParams({ ...requestParams,
      startDate: moment(startDate).format("DD-MM-yyyy"),
    })
    params.startDate = moment(startDate).format("DD-MM-yyyy")
  }

  if (startDate) {
    setLoading(true)
    dispatch({ type: SOA_CUSTOMER_REPORT_API_CALL, payload: params });
  }
}

const onClearDate = () => {
  setRequestParams({ ...requestParams,
    endDate: "",
    startDate: "",
  })
  setStartDate(null)
  setEndDate(null)
  setLoading(true)
  let params = requestParams
  params.startDate = ""
  params.endDate = ""
  dispatch({ type: SOA_CUSTOMER_REPORT_API_CALL, payload: requestParams});
}

const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <div style={{display: 'flex', backgroundColor: '#D6D6D6', paddingRight: 10, alignItems: 'center', borderRadius: 5}}>
  <button  style={{paddingLeft: 20, paddingRight: 20, backgroundColor: '#D6D6D6', borderWidth: 0, paddingTop: 8, paddingBottom: 8, color: '#1d1d5e', fontWeight: '600', borderRadius: 5 }} onClick={onClick} ref={ref}>
    {value ? value : 'Search with date'} 
  </button>
  <div style={{backgroundColor: '#1d1d5e', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 8, width: 16, height: 16, padding: 5, cursor: 'pointer'}} onClick={onClearDate}>
    <label style={{fontWeight: 'bold',  fontSize: 10, color: '#D6D6D6', cursor: 'pointer'}}>X</label>
  </div>
  </div>
));

  return (
    <>
    <ToastContainer />
     <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
      <div style={{ paddingLeft: 50, paddingRight: 50, marginTop: 75 }}>
        <Container fluid>
          <Row>
            <Col
              style={{
                fontSize: 16,
                fontWeight: "500",
                color: "#6E6A6A",
                textDecoration: "underline",
              }}
            >
              Statement of Accounts - Customer
            </Col>
            <Col className="col-6 text-end">
              {SelectedCustomer ? (
                <p key={SelectedCustomer.id}>
                  <strong>{SelectedCustomer.name}</strong>
                  <br />
                  {SelectedCustomer.addresses &&
                  SelectedCustomer.addresses.length > 0 ? (
                    <>
                      <small>
                        {SelectedCustomer.addresses[0]?.addressLine1}
                      </small>
                      ,<br />
                      <small>{SelectedCustomer.addresses[0]?.city}</small>,
                      <small>
                        {SelectedCustomer.addresses[0]?.countryName}
                      </small>
                      {/* <br /><small>{SelectedCustomer.addresses[0]?.zipcode}</small> */}
                    </>
                  ) : (
                    <small></small>
                  )}
                  <br />
                  <small>{SelectedCustomer.mobile}</small>
                </p>
              ) : (
                <p>NA</p>
              )}
            </Col>
          </Row>

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 20, marginTop: 25}}>
          <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              onCalendarClose={onDateSelected}
              startDate={startDate}
              isClearable={false}
              endDate={endDate}
              dateFormat={"dd/MM/yyyy"}
              placeholderText="Select Date Range"
              selectsRange
              customInput={<DatePickerInput />}
            />
          </div>

          <div
            className="mt-3 p-1"
            style={{ overflowY: "scroll", minHeight: 380, maxHeight: 500 }}
          >
            <Table size="sm" striped hover bordered>
              <thead style={{}}>
                <tr>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    DATE
                  </th>
                  <th
                    className="text-start ps-4"
                    style={{ backgroundColor: "#1d1d5e", color: "white" }}
                  >
                    CUSTOMER NAME
                  </th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    ORDER NO
                  </th>
                  {/* <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    DESCRIPTION
                  </th> */}
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    DR(AED)
                  </th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    CR(AED)
                  </th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                    BALANCE(AED)
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={3}
                    className="text-start ms-3 gap-3"
                    style={{
                      color: "#6E6A6A",
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                  >
                    OPENING BALANCE AS ON STARTING DATE
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {soaCustomer &&
                  soaCustomer.map((item) => (
                    <tr key={item.id}>
                      <td>{item.creditedDate}</td>
                      <td className="text-start ps-4">{item.name}</td>
                      <td>{item.orderId}</td>
                      <td>
                        {item.credited.toString() === "true"
                          ? item.amount.toFixed(2)
                          : "-"}
                      </td>
                      <td>
                        {item.credited.toString() === "false"
                          ? item.amount.toFixed(2)
                          : "-"}
                      </td>
                      <td>{item.outStandingAmount.toFixed(2)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div
            className="mt-2 text-end me-3 fs-5"
            style={{
              textDecoration: "underline",
              lineHeight: 1.4,
              letterSpacing: -0.7,
              cursor: "pointer",
              textUnderlineOffset: 4,
            }}
          >
            <u onClick={handlesubmitpdfgenerate}>
              {" "}
              <IoMdDownload
                style={{
                  fontSize: 20,
                }}
              />{" "}
              E- Statement of Customer 
            </u>
          </div>
        </Container>
      </div>
       {loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              top: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // display: "flex",
            }}
          >
            <div
              style={{
                backgroundColor: "#EEEEEE33",
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BeatLoader color="#1d1d5e" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapsToProps = (state) => {
  return {
    customers: state.customers,
    report: state.report,
    common: state.commonReducer
  };
};

export default connect(mapsToProps)(CustomerSOAList);
