import { AxiosConfig } from "../../Networking/AxiosConfig";

export const GetAllPurchaseOrder = (data) => {
    return AxiosConfig.post('/v2/purchaseOrder/getPurchaseOrders', data);
}

export const CreatePurchaseOrder = (data) => {
    return AxiosConfig.post('/v2/purchaseOrder/createPurchaseOrder', data)
}

export const generatePoPDF = (data) => {
    const params = JSON.stringify({purchaseOrderId: data})
    return AxiosConfig.post('/v2/pdf/generatePOPdf', {
        requestId: data
    })
}

export const GetPurchaseOrderDetails = (purchaseId) => {
    return AxiosConfig.post('/v2/purchaseOrder/getPurchaseOrderDetails', purchaseId)
}