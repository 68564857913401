import React, { useEffect, useState, forwardRef } from "react";
import {
  Container,
  FormSelect,
  Pagination,
  Row,
  Table,
  Button,
  Col,
  InputGroup,
  FormControl,
  Form
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FaSearch } from "react-icons/fa";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { useDispatch, connect } from "react-redux";
import { GENERATE_RECEIPT_PDF_API_CALL, GET_ALL_RECEIPT_API_CALL, ERROR_CODE, ERROR_CODE_FAILURE, RESET_CODE, SUCCESS_CODE_NO } from "../../utils/Constant";
import { FiDownload } from "react-icons/fi";
import { BeatLoader } from "react-spinners";
import moment from 'moment';
import DatePicker from "react-datepicker";

const  Receipt = (props) => {
  const [entitiesPerPage, setEntitiesPerPage] = useState("");
  const [search, setSearch] = useState("");
  // const [filteredReceipt, setFilteredReceipt] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [startingIndex, setStartIndex] = useState(0)
  const [endingIndex, setEndingIndex] = useState(15)
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(0)

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [isPdfDownload, setIsPDFDownload] = useState(false)
  const [selectedPaymentType, setSelecedPaymentType] = useState(0)

  const dispatch = useDispatch();
  console.log(props)

  useEffect(() => {
    dispatch({type: GET_ALL_RECEIPT_API_CALL,payload:{loginUserId: props.user.loginId}})
  }, [])

  // console.log("the data pages",entitiesPerPage);
  
  useEffect(() => {
    setLoading(false)
      if (props.common.code === ERROR_CODE_FAILURE) {
        setLoading(false)
        dispatch({ type: RESET_CODE });
      }
  }, [props.common.code])

  useEffect(() => {
    setLoading(false);
    if (props.common.successCode === SUCCESS_CODE_NO && isPdfDownload) {
      window.open(props.receipt.fileurl, "_blank");
      setIsPDFDownload(false)
      // dispatch({ type: RESET_INVOICE_CODE });
    }
    dispatch({ type: RESET_CODE });
  }, [props.common.successCode]);

  const handleDateChange = (dates) => {
    if (!dates) {
      setStartDate(new Date());
      setEndDate(null);
    } else {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const onDateSelected = () => {
    const dateParams = {loginUserId: props.user.loginId, createdBy: selectedId, paymentType: selectedPaymentType}
    if (endDate) {
      dateParams["endDate"] = moment(endDate).format("DD-MM-yyyy")
    }
    if (startDate) {
      dateParams["startDate"] = moment(startDate).format("DD-MM-yyyy")
    }

    if (startDate) {
      setLoading(true)
      dispatch({ type: GET_ALL_RECEIPT_API_CALL, payload: dateParams });
    }
  }

  const onClearDate = () => {
    setStartDate(null)
    setEndDate(null)
    setLoading(true)
    dispatch({ type: GET_ALL_RECEIPT_API_CALL, payload: {loginUserId: props.user.loginId, createdBy: selectedId, paymentType: selectedPaymentType}});
  }

  const handleItemSelect = (id) => {
    const dateParams = {loginUserId: props.user.loginId, paymentType: selectedPaymentType}
    if (endDate) {
      dateParams["endDate"] = moment(endDate).format("DD-MM-yyyy")
    }
    if (startDate) {
      dateParams["startDate"] = moment(startDate).format("DD-MM-yyyy")
    }
    dateParams["createdBy"] = id
    setSelectedId(id)
    setLoading(true)
    dispatch({ type: GET_ALL_RECEIPT_API_CALL, payload: dateParams});
  }

  const handlePaymentTypeChanged = (id) => {
    setSelecedPaymentType(id)
    const paymentTypeParams = {loginUserId: props.user.loginId, createdBy: selectedId}

    if (endDate) {
      paymentTypeParams["endDate"] = moment(endDate).format("DD-MM-yyyy")
    }
    if (startDate) {
      paymentTypeParams["startDate"] = moment(startDate).format("DD-MM-yyyy")
    }

    paymentTypeParams["paymentType"] = id

    setLoading(true)
    dispatch({ type: GET_ALL_RECEIPT_API_CALL, payload: paymentTypeParams});

  }

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{ display: 'flex', backgroundColor: '#D6D6D6', paddingRight: 10, alignItems: 'center', borderRadius: 5 }}>
      <button style={{ paddingLeft: 20, paddingRight: 20, backgroundColor: '#D6D6D6', borderWidth: 0, paddingTop: 8, paddingBottom: 8, color: '#1d1d5e', fontWeight: '600', borderRadius: 5 }} onClick={onClick} ref={ref}>
        {value ? value : 'Search with date'}
      </button>
      <div style={{ backgroundColor: '#1d1d5e', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 8, width: 16, height: 16, padding: 5, cursor: 'pointer' }} onClick={onClearDate}>
        <label style={{ fontWeight: 'bold', fontSize: 10, color: '#D6D6D6', cursor: 'pointer' }}>X</label>
      </div>
    </div>
  ));

 
  const renderPagination = () => {
    // let totalItems = 0;
    // if (filteredReceipt != null) {
    //   totalItems = filteredReceipt.length;
    // }
   
    // const itemsPerPage = entitiesPerPage ? parseInt(entitiesPerPage) : 15;
    // const totalPages = Math.ceil(totalItems / itemsPerPage);
    const totalItems = props.receipt.listAllReceipt.length;
    const itemsPerPage = 15;
    const totalPages = Math.ceil(totalItems/itemsPerPage);
  
    const getPageNumbers = () => {
      const pageNumbers = [];
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    };
  
    const pageNumbers = getPageNumbers();
  
    const handleClick = (page) => {
      setCurrentPage(page);
  
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = page * itemsPerPage;
  
      setStartIndex(startIndex);
      setEndingIndex(endIndex);
    };
  
    const renderPageNumbers = pageNumbers.map((number) => {
      if (
        (number === 1 || number === totalPages) ||
        (number >= currentPage - 1 && number <= currentPage + 1 && currentPage !== totalPages)
      ) {
        return (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
      if ((number === 2 && currentPage > 3) || (number === totalPages - 1 && currentPage < totalPages - 2)) {
        return <Pagination.Ellipsis key={number} />;
      }
      return null;
    });
  
    return (
      <Pagination>
        <Pagination.First onClick={() => handleClick(1)} />
        <Pagination.Prev
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {renderPageNumbers}
        <Pagination.Next
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last onClick={() => handleClick(totalPages)} />
      </Pagination>
    );
  };

  // useEffect(()=> {
  //   if (props.receipt.code === 100) {
  //     window.open(props.receipt.fileurl, "_blank");
  //     setLoading(false);
  //   }
  // }, [props.receipt.code])  
  
  return (
    <div className="mt-5" style={{ paddingLeft: 50, paddingRight: 50 }}>
      <div style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
      <Container fluid>
        <div
          className="d-flex mt-4 pt-4 "
          style={{
            // border: "1px solid #80808042",
            paddingLeft: "1%",
            paddingRight: "1%",
            marginBottom: "1%",
          }}
        >
          <Col style={{display: 'flex', flex: 1}}>
            <Link to="/Customerpay">
              <Button
                className="b-none fw-bolder"
                style={{ backgroundColor: "#1d1d5e", color: "white" }}
              >
                New Receipt
              </Button>
            </Link>
          </Col>
          <Col style={{display: 'flex', flex: 1}}>
            <InputGroup style={{ height: "10px", width: "100%" }}>
              <InputGroupText style={{ backgroundColor: "#1d1d5e " }}>
                <FaSearch className="text-white" />
              </InputGroupText>
              <FormControl
                placeholder="Search Customer..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                style={{
                  background: "#80808036",
                  boxShadow: "none",
                  outline: "none",
                  borderColor: "white",
                  height: "35px",
                }}
              />
            </InputGroup>
          </Col>


          <Col style={{display: 'flex', flex: 3}} className="d-flex align-items-center justify-content-end me-3">
            <div className="d-flex align-items-start pt-1 ms-3" style={{flex: 1, justifyContent: 'flex-end'}}>

            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                  <Form.Select
                  className="inputfocus rounded-0"
                  onChange={e => { handlePaymentTypeChanged(e.target.value) }}
                  style={{ width: 170, height: 30, fontSize: 12 }}
                  defaultChecked={0}
                >
                  <option value={0}>All Payment Type</option>
                  {
                    props?.receipt?.paymentType?.map(type => {
                      return <option key={type.id} value={type.id} style={{ fontSize: 12 }}>{type.name}</option>
                    })
                  }
                </Form.Select>
                </div>
              
            {
                props.user.loginRoleId === 1 && <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                  <Form.Select
                  className="inputfocus rounded-0"
                  onChange={e => { handleItemSelect(e.target.value) }}
                  style={{ width: 170, height: 30, fontSize: 12 }}
                  defaultChecked={0}
                >
                  <option value={0}>All Users</option>
                  {
                    props?.receipt?.createdByList?.map(user => {
                      return <option key={user.id} value={user.id} style={{ fontSize: 12 }}>{user.name}</option>
                    })
                  }
                </Form.Select>
                </div>
              }
              <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                onCalendarClose={onDateSelected}
                startDate={startDate}
                isClearable={false}
                endDate={endDate}
                dateFormat={"dd/MM/yyyy"}
                placeholderText="Select Date Range"
                selectsRange
                customInput={<DatePickerInput />}
              />
            
              </div>
         
            </div>
          </Col>
        </div>
        <div style={{paddingTop: 15}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div>
              {
                props?.receipt?.endDate? <label style={{fontSize: 16,}}>Data available from <span style={{fontWeight: 'bold'}}>{props?.receipt?.startDate}</span> to <span style={{fontWeight: 'bold'}}>{props?.receipt?.endDate}</span></label> : <label style={{fontSize: 16,}}>
                  Data showing <span style={{fontWeight: 'bold'}}>{props?.receipt?.startDate}</span>
                </label>
              }
              
              </div>

              <div style={{marginLeft: 20}}>
              <label style={{fontSize: 16,}}>Total Amount Received <span style={{fontWeight: 'bold', fontSize: 18}}>{props?.receipt?.totalAmount}</span> AED</label>
              </div>
          </div>
        </div>
        <div style={{paddingTop: 15}} >
          <Table striped hover size="sm" bordered>
            <thead>
              <tr>
                <th className="text-start" style={{ backgroundColor: "#1d1d5e", color: "white", paddingLeft: '1.5%' }}>
                  Receipt ID
                </th>
                <th  className="text-start" style={{ backgroundColor: "#1d1d5e", color: "white", paddingLeft: '2%' }}>
                  Customer Name
                </th>
                <th className="text-start" style={{ backgroundColor: "#1d1d5e", color: "white", paddingLeft: '2%' }}>Date</th>
                <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                  Mode of Pay
                </th>
                <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>
                  Amount
                </th>
                <th className="text-start" style={{ backgroundColor: "#1d1d5e", color: "white", paddingLeft: '2%' }}>
                  Reference Number
                </th>
                <th className="text-start" style={{ backgroundColor: "#1d1d5e", color: "white", paddingLeft: '2%' }}>Description</th>
                <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Action</th>
              </tr>
            </thead>
            <tbody>
            {props.receipt.listAllReceipt && props.receipt.listAllReceipt.slice(startingIndex, endingIndex).map((item) => (
                  <tr key={item.id}>
                    <td className="text-start" style={{ paddingLeft: '1.5%' }}>{item.billReference}</td>
                    <td className="text-start" title={item.customerName} style={{ paddingLeft: '2%' }}>{item.customerName.length > 15 ? `${item.customerName.substring(0,15)}...` : item.customerName}</td>
                    <td>{item.createdAt}</td>
                    <td className="text-start" style={{ paddingLeft: '3.5%' }}>{item.paymentTypeName}</td>
                    <td className="text-start" style={{ paddingLeft: '1%' }}>{item.amount}</td>
                    <td className="text-start" style={{ paddingLeft: '2%' }} title={item.referenceNumber}>{item.referenceNumber.length > 20 ? `${item.referenceNumber.substring(0,20)}...` : item.referenceNumber ? item.referenceNumber : "-"}</td>
                    <td className="text-start" style={{ paddingLeft: '2%' }} title={item.description}>{item.description.length > 20 ? `${item.description.substring(0,20)}...` : item.description ? item.description : "N/A"}</td>
                    <td>
                      <FiDownload style={{ cursor: 'pointer' }} onClick={()=>{
                        if(item.receiptUrl) {
                          window.open(item.receiptUrl, "_new");
                        } else {
                          setIsPDFDownload(true)
                          setLoading(true);
                          dispatch({type: GENERATE_RECEIPT_PDF_API_CALL, payload: item.billReference})
                        }                        
                      }} />
                    </td>
                  </tr>
                ))}
              {props.receipt.listAllReceipt && props.receipt.listAllReceipt.length === 0 && (
                <tr>
                  <td
                    colSpan={8}
                    className="fst-italic"
                    style={{ color: "red" }}
                  >
                    No data found!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-center ms-auto text-center mt-3">
          <Pagination size="md"></Pagination>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20, paddingBottom: 100 }}>
        {
          props.receipt.listAllReceipt?.length > 0 && renderPagination()
        }
      </div>
      </Container>
      {loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              top: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#EEEEEE33",
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BeatLoader color="#1d1d5e" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapsToProps = (state) => {
  return {
    customers: state.customers,
    receipt: state.receipt,
    common: state.commonReducer,
    user: state.users
  };
};

export default connect(mapsToProps)(Receipt);
