import React, { useState } from "react";
import { Button, FormControl, Form } from "react-bootstrap";
import { useDispatch, connect } from "react-redux";

import { ADD_CREDIT_NOTE_API_CALL } from "../../utils/Constant";

const DebitNotesTableBody = (props) => {

    const [showInputField, setShowInputField] = useState(false)
    const [returnPrice, setReturnPrice] = useState(props.item.finalAmount)
    const [reason, setReason] = useState()

    const dispatch = useDispatch()

    const onButtonClick = () => {
        props.updateIdSelection(props.item.id)
        setShowInputField(true)
    }

    const onSaveClicked = () => {
        const params = {
            finalAmount:  returnPrice,
            type: 2,
            reason: reason,
            createdBy: props.loggedInUser.loginId,
            id: props.item.id,
            customerType: 1
        }

        console.log(returnPrice)

        if (returnPrice == 0 || returnPrice > 0 && returnPrice > props.item.finalAmount ) {
            props.updateError("Please check the credit amount.")
            return;
        }
        if (reason === undefined) {
            props.updateError("Please Enter the reason.")
            return;
        }

        props.showLoader()
        dispatch({type: ADD_CREDIT_NOTE_API_CALL, payload: params})
    }

    const reasonChange = (e) => {
        setReason(e.target.value)
    }

    const onCancelClicked = () => {
        setReturnPrice(props.item.finalAmount)
        setShowInputField(false)
        props.updateIdSelection(0)
    }

    const onChangeText = (e) => {
        setReturnPrice(e.target.value)
    }
    return <tr key={props.item.id}>
        <td>{props.item.productName}</td>
        <td>{props.item.quantity}</td>
        <td style={{display: 'flex', justifyContent: 'center'}}>
            <label> {props.item.baseUnitPrice}</label>
           </td>
        <td>{props.item.discountPercentage ? props.item.discountPercentage : "-"}</td>
        <td>{props.item.vatPercentage ? props.item.vatPercentage : "-"}</td>
        <td style={{ width: 160}}>{
            showInputField && props.selectedId === props.item.id ? <Form.Control
            type="integer"
            style={{ width: 170, height: "30px" }}
            placeholder="Enter total debit amount"
            value={returnPrice}
            onChange={onChangeText}
          /> : props.item.finalAmount ? (props.item.finalAmount).toFixed(2) : "-"}</td>
        <td style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Form.Control
            type="string"
            style={{ width: 250, height: "30px" }}
            placeholder="Enter the reason"
            value={reason}
            disabled={!showInputField || props.selectedId !== props.item.id}
            onChange={reasonChange}
          />
        </td>
        <td style={{ width: 160 }}>
            {
                showInputField && props.selectedId === props.item.id ? <>
                    <Button
                    style={{
                        backgroundColor: "#1d1d5e",
                        borderColor: "#1d1d5e",
                        fontSize: 10,
                        fontWeight: 700
                    }}

                    onClick={onSaveClicked}
                >
                    Save
                </Button> 
                <Button
                    style={{
                        backgroundColor: "#1d1d5e",
                        borderColor: "#1d1d5e",
                        fontSize: 10,
                        fontWeight: 700,
                        marginLeft: 5
                    }}

                    onClick={onCancelClicked}
                >
                    Cancel
                </Button>
                </> : <Button
                    style={{
                        backgroundColor: "#1d1d5e",
                        borderColor: "#1d1d5e",
                        fontSize: 10,
                        fontWeight: 700
                    }}

                    onClick={onButtonClick}
                >
                    Add Debit Notes
                </Button>
            }

        </td>
    </tr>
}

const mapsToProps = (state) => {
    return {
      loggedInUser: state.users,
    };
  };

export default connect(mapsToProps)(DebitNotesTableBody);