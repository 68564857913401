import { AxiosConfig } from "../../Networking/AxiosConfig"

export const MasterApiCall = () => {
    return AxiosConfig.get('/v2/common/getmaster')
}

export const CountriesApiCall = () => {
    return AxiosConfig.get('/v2/country/get')
}

export const AddCountry = (data) => {
    return AxiosConfig.post('/v2/country/add', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}