import React, { useState, forwardRef, useEffect } from "react";
import { Container, Row, Col, Button, InputGroup, FormControl, Form, Table } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FaSearch } from "react-icons/fa";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useDispatch, connect } from "react-redux";
import { GET_ALL_CREDIT_DEBIT_NOTES_REQUEST } from "../../utils/Constant";

const CreditDebitNote = (props) => {

  const [search, setSearch] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const dispatch = useDispatch()

  console.log(props)

  useEffect(() => {
    dispatch({type: GET_ALL_CREDIT_DEBIT_NOTES_REQUEST})
  }, [])


  const handleItemSelect = () => {

  }

  const handleDateChange = () => {

  }

  const onDateSelected = () => {

  }

  const onClearDate = () => {

  }

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{ display: 'flex', backgroundColor: '#D6D6D6', paddingRight: 10, alignItems: 'center', borderRadius: 5 }}>
      <button style={{ paddingLeft: 20, paddingRight: 20, backgroundColor: '#D6D6D6', borderWidth: 0, paddingTop: 8, paddingBottom: 8, color: '#1d1d5e', fontWeight: '600', borderRadius: 5 }} onClick={onClick} ref={ref}>
        {value ? value : 'Search with date'}
      </button>
      <div style={{ backgroundColor: '#1d1d5e', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 8, width: 16, height: 16, padding: 5, cursor: 'pointer' }} onClick={onClearDate}>
        <label style={{ fontWeight: 'bold', fontSize: 10, color: '#D6D6D6', cursor: 'pointer' }}>X</label>
      </div>
    </div>
  ));

  return <div style={{ marginTop: 75 }}>
    <Container fluid className="mt-2">
      <div style={{ paddingLeft: 50, paddingRight: 50 }}>
        <Row style={{ marginTop: "2%" }}>
          <Col className="col-8" style={{ paddingLeft: "2%" }}>
            <div className="d-flex">
              <Link
                to="/add/credit-debit"
                style={{ textDecoration: "none", color: "white", display: 'none' }}
              >
                <Button
                  style={{
                    backgroundColor: "#1d1d5e",
                    borderColor: "#1d1d5e",
                    width: "80px",
                  }}
                >
                  New +
                </Button>
              </Link>
              <InputGroup
                style={{ height: "10px", width: "39%", marginLeft: "10%" }}
              >
                <InputGroupText style={{ backgroundColor: "#1d1d5e " }}>
                  <FaSearch className="text-white" />
                </InputGroupText>
                <FormControl
                  placeholder="Search user..."
                  onChange={(e) => setSearch(e.target.value)}
                  style={{
                    background: "#80808036",
                    boxShadow: "none",
                    outline: "none",
                    borderColor: "white",
                  }}
                />
              </InputGroup>
            </div>
          </Col>
          <Col
            className="d-flex" style={{ justifyContent: props.users.loginRoleId === 1 ? 'space-between' : 'flex-end', alignItems: 'center' }}
          >
            {
              props.users.loginRoleId === 1 && <Form.Select
                className="inputfocus rounded-0"
                onChange={e => { handleItemSelect(e.target.value) }}
                style={{ width: 170, height: 30, fontSize: 12 }}
                defaultChecked={0}
              >
                <option value={0}>All Users</option>
                {
                  props?.invoice?.createdByList?.map(user => {
                    return <option key={user.id} value={user.id} style={{ fontSize: 12 }}>{user.name}</option>
                  })
                }
              </Form.Select>
            }


            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              onCalendarClose={onDateSelected}
              startDate={startDate}
              isClearable={false}
              endDate={endDate}
              dateFormat={"dd/MM/yyyy"}
              placeholderText="Select Date Range"
              selectsRange
              customInput={<DatePickerInput />}
            />
          </Col>
        </Row>
      </div>

      <div style={{paddingLeft: 50, paddingRight: 50, paddingTop: 50}}>
            <Table>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Created At</th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Invoice Id</th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Product Name</th> 
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Invoice Amount</th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Base Amount</th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Quantity</th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Credit/Debit amount</th>
                  <th style={{ backgroundColor: "#1d1d5e", color: "white" }}>Reason</th>
                </tr>
              </thead>

              <tbody>
                  {
                    props.creditDebit.listCreditDebitNotes.map(item => {
                      return <tr>
                        <td>{item.createdTime}</td>
                        <td>{item.invoiceId}</td>
                        <td>{item.productName}</td>
                        <td>{item.invoiceAmount} AED</td>
                        <td>{item.baseAmount}</td>
                        <td>{item.quantity}</td>
                        <td>{item.finalAmount} AED</td>
                        <td>{item.reason}</td>
                      </tr>
                    })
                  }
              </tbody>
            </Table>
      </div>

    </Container>

  </div>
}

const mapsToProps = (state) => {
  return {
    invoice: state.invoice,
    users: state.users,
    common: state.commonReducer,
    creditDebit: state.creditDebit
  };
};

export default connect(mapsToProps)(CreditDebitNote);