import { countries } from "country-flag-icons";
import { MASTER_API_RESPONSE, GET_ALL_COUNTRIES_API_RESPONSE } from "../../utils/Constant";

const INITIAL_STATE = {
    addressTypes: [],
    businessTypes: [],
    customerCategories: [],
    userRoles: [],
    handtCategories: [],
    paymentTypes: [],
    countries: []
}

const MasterReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case MASTER_API_RESPONSE:
            return { ...state, addressTypes: action.payload.addressTypes, businessTypes: action.payload.businessTypes, customerCategories: action.payload.customerCategories, userRoles: action.payload.userRoles, handtCategories: action.payload.handtCategories, paymentTypes: action.payload.paymentTypes }

        case GET_ALL_COUNTRIES_API_RESPONSE:
            return { ...state, countries: action.payload.data }
    }
    return state
}

export default MasterReducer;