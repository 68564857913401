import { act } from "react-dom/test-utils";
import {
  GET_ALL_INVOICE_API_RESPONSE,
  RESET_INVOICE_ARRAY,
  GENERATE_INVOICE_PDF_API_RESPONSE,
  RESET_INVOICE_CODE,
  GET_INVOICE_DETAILS_API_RESPONSE,
  CLEAR_INVOICE_LIST,
  RESET_ALL_STATE_VALUES
} from "../../utils/Constant";


const INITIAL_STATE = {
  invoiceList: [],
  invoiceDetails: {},
  paidAmount: 0,
  unpaidAmount: 0,
  totalAmount: 0,
  totalOrders: 0,
  fileurl: '',
  code: 0,
  createdByList: []
};

const InvoiceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_INVOICE_API_RESPONSE: {
      return { ...state, invoiceList: action.payload.invoiceList, paidAmount: action.payload.paidAmount, unpaidAmount: action.payload.unPaidAmount, totalAmount: action.payload.totalInvoiceAmount, totalOrders: action.payload.totalOrder, createdByList: action.payload.createdByList};
    }
      
    case RESET_INVOICE_ARRAY: 
      return { ...state, listInvoice: [] };

    case GENERATE_INVOICE_PDF_API_RESPONSE: 
        return {...state, fileurl: action.data, code: 100}
    
    case GET_INVOICE_DETAILS_API_RESPONSE:
      return {...state, invoiceDetails: action.payload}

    case RESET_INVOICE_CODE: {
      return {...state, code: 0}
    }

    case CLEAR_INVOICE_LIST: 
      return {...state, invoiceList: [], unpaidAmount: 0, totalAmount: 0, paidAmount: 0, totalOrders: 0}
    
      case RESET_ALL_STATE_VALUES: {
        return INITIAL_STATE;
      }
  }
  return state;
};

export default InvoiceReducer;
