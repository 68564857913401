import { takeEvery, call, put, take } from "redux-saga/effects";
import { GET_ALL_CREDIT_DEBIT_NOTES_REQUEST, GET_ALL_CREDIT_DEBIT_NOTES_RESPONSE, ADD_CREDIT_NOTE_API_CALL, ADD_CREDIT_NOTE_API_RESPONSE, SUCCESS_CODE, ERROR_CODE, ADD_DEBIT_NOTE_API_CALL, ADD_DEBIT_NOTE_API_RESPONSE } from "../utils/Constant";

import { GetAllCreditDebitRequest, AddDebitCreditRecord, AddDebitRecordForCustomer } from "../Reducer/Action/CreditDebitAction";


function* GetAllCreditDebitAPICall() {
    const response = yield call(GetAllCreditDebitRequest)
    if (response.status === 200) {
        if (response.data.code === 200) {
            console.log(response.data)
            yield put({type: GET_ALL_CREDIT_DEBIT_NOTES_RESPONSE, payload: response.data})
        }
    }
}

function* AddCreditNotesApiRequest(data) {
    const response = yield call(AddDebitCreditRecord, data.payload)
    if (response.status === 200) {
        if (response.data.code === 200) {
            yield put({type: ADD_CREDIT_NOTE_API_RESPONSE})
            yield put({ type: SUCCESS_CODE })
        }
        else {
            yield put({
                type: ERROR_CODE,
                payload: { message: response.data.message },
              });
        }
        
    }
    else {
        yield put({
            type: ERROR_CODE,
            payload: { message: 'Something went wrong' },
          });
    }
}

function* AddDebitNoteAPICall(data) {
    const response = yield call(AddDebitRecordForCustomer, data.payload)
    if (response.status === 200) {
        if (response.data.code === 200) {
            yield put({type: ADD_DEBIT_NOTE_API_RESPONSE})
            yield put({ type: SUCCESS_CODE })
        }
        else {
            yield put({
                type: ERROR_CODE,
                payload: { message: response.data.message },
              });
        }
    }
}

function* CreditDebitSaga() {
    yield takeEvery(GET_ALL_CREDIT_DEBIT_NOTES_REQUEST, GetAllCreditDebitAPICall)
    yield takeEvery(ADD_CREDIT_NOTE_API_CALL, AddCreditNotesApiRequest)
    yield takeEvery(ADD_DEBIT_NOTE_API_CALL, AddDebitNoteAPICall)
}

export default CreditDebitSaga;