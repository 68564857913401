import { AxiosConfig } from "../../Networking/AxiosConfig";

export const GetAllCreditDebitRequest = () => {
    return AxiosConfig.get('dtcr/get/customer')
}

export const AddDebitCreditRecord = (data) => {
    return AxiosConfig.post('dtcr/add/credit-notes', data)
}

export const AddDebitRecordForCustomer = (data) => {
    return AxiosConfig.post('/dtcr/add/debit-notes', data)
}