import { GET_ALL_CREDIT_DEBIT_NOTES_RESPONSE } from "../../utils/Constant" 
const INITIAL_STATE = {
    listCreditDebitNotes: []
}


const CreditDebitNotesReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_ALL_CREDIT_DEBIT_NOTES_RESPONSE: {
            return {...state, listCreditDebitNotes: action.payload.data}
        }
    }

    return state
}

export default CreditDebitNotesReducer