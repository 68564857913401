import React, { useState, useEffect, useCallback } from "react";
import ResetPassword from "../login/ResetPassword";
import { Button, Col, Container, FloatingLabel, FormControl, Image, Row, Form } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone'
import { RESET_PASSWORD_API_CALL, KEY_IS_LOGGED_IN, USER_ACCOUNT_LOGOUT, storeToLocalStorage, RESET_CODE, SUCCESS_CODE_NO, RESET_ALL_STATE_VALUES, GET_ALL_COUNTRIES_API_REQUEST, ADD_COUNTRIES_API_REQUEST, ERROR_CODE_FAILURE } from "../../utils/Constant";

const ProfilePage = (props) => {

    const [selectedIndex, setSelectedIndex] = useState(1)
    const [title, setTitle] = useState("Profile")
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [isAddCountryVisible, setAddCountryVisible] = useState(false)
    const [countryName, setCountryName] = useState()
    const [countryCode, setCountryCode] = useState()
    const [currency, setCurrency] = useState()
    const [mobileLength, setMobileLength] = useState()
    const [mobileCode, setMobileCode] = useState()
    const [selectedFiles, setSelectedFiles] = useState()
    const [image, setImage] = useState()
    const [addCountry, setAddCountry] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({ type: RESET_CODE });
        setLoading(false)
        if (props.common.successCode === SUCCESS_CODE_NO) {
            toast(addCountry ? "Country Added Successfully" : "Password updated successfully", {
                type: "success",
            });

            if (addCountry) {
                setAddCountry(false)
                setAddCountryVisible(false)
                setCountryName(null)
                setCountryCode(null)
                setCurrency(null)
                setMobileLength(null)
                setMobileCode(null)
                setSelectedFiles(null)
                dispatch({ type: GET_ALL_COUNTRIES_API_REQUEST })
            }

        }
    }, [props.common.successCode])

    useEffect(() => {
        if (props.common.code === ERROR_CODE_FAILURE) {
            setLoading(false)
            dispatch({ type: RESET_CODE });

            toast(props.common.errorMessage, {
                type: "error",
              });
          }
    }, [props.common.code])

    useEffect(() => {
        dispatch({ type: GET_ALL_COUNTRIES_API_REQUEST })
    }, [])

    const onDrop = useCallback(acceptedFiles => {
        setSelectedFiles(acceptedFiles[0])
        setImage(URL.createObjectURL(acceptedFiles[0]))
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const profileClicked = () => {
        setSelectedIndex(1)
        setTitle("Reset Password")
    }

    const countriesClicked = () => {
        setSelectedIndex(2)
        setTitle("Countries")
    }

    const logoutClicked = () => {
        setSelectedIndex(3)
        setTitle("Logout")
    }

    const handleResetPassword = () => {
        if (password.length === 0) {
            setError("Invalid Password");
            return;
        }
        if (confirmPassword.length === 0 || !passwordMatch) {
            setError("Invalid Confirm Password");
            return;
        }
        if (password === props.loginUsers.password) {
            toast.error("New password must be different from the old password");
            return;
        }

        const bodyData = {
            userId: props.loginUsers.loginId,
            password: password,
        }

        // axios.post('http://97.74.94.57:8080/handt/v2/account/reset-password', bodyData).then(response => {
        //   console.log(response.data.data);
        //   toast.success("Password reset successfully");
        //   storeToLocalStorage(KEY_IS_LOGGED_IN, false);
        //   dispatch({ type: USER_ACCOUNT_LOGOUT })

        // }).catch(error => {
        //   console.log("Error Encountered", error);
        //   toast.error("Failed to reset password");
        // })
        setLoading(true)
        dispatch({ type: RESET_PASSWORD_API_CALL, data: bodyData })
        // storeToLocalStorage(KEY_IS_LOGGED_IN, false);
        // dispatch({ type: USER_ACCOUNT_LOGOUT })
    }

    const handlePassword = (e) => {
        const { name, value } = e.target;
        if (name === 'password') {
            setPassword(value);
        }
        if (name === 'confirm password') {
            setConfirmPassword(value);
            if (password === value) {
                setPasswordMatch(true);
            } else {
                setPasswordMatch(false);
            }
        }
    }

    const countryNameChange = (value) => {
        setCountryName(value)
    }

    const countryCodeChange = (value) => {
        setCountryCode(value)
    }
    const currencyChange = (value) => {
        setCurrency(value)
    }

    const handleMobileLength = (value) => {
        setMobileLength(value)
    }

    const handleMobileCode = (value) => {
        setMobileCode(value)
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const cancel = () => {
        setSelectedIndex(1)
    }

    const logout = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            navigate("/")
            storeToLocalStorage(KEY_IS_LOGGED_IN, false);
            dispatch({ type: RESET_ALL_STATE_VALUES })
            dispatch({ type: USER_ACCOUNT_LOGOUT })
        }, [2000])

    }

    const enableAddCountry = () => {
        setAddCountryVisible(true)
    }

    const uploadCountryDetails = () => {

        if (countryCode && mobileCode && mobileLength && countryName && currency) {
            const formData = new FormData();
            formData.append("countryCode", countryCode)
            formData.append("countryMobileCode", mobileCode)
            formData.append("countryMobileLength", mobileLength)
            formData.append("countryName", countryName)
            formData.append("currencyCode", currency)
            formData.append("files", selectedFiles)

            setAddCountry(true)
            setLoading(true)

            dispatch({ type: ADD_COUNTRIES_API_REQUEST, payload: formData })
        }
        else {
            setShowErrorMessage(true)
        }

    }

    return <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <ToastContainer />
        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 100, paddingRight: 75, paddingLeft: 75, height: '70vh' }}>
            <div style={{ borderColor: '#E5E5E5', borderWidth: 1, borderStyle: 'solid', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, flex: 2 }}>
                <div style={{ paddingLeft: 50, paddingRight: 50, backgroundColor: selectedIndex === 1 ? '#1d1d5e' : '#FFFFFF', paddingTop: 12, paddingBottom: 12, borderTopLeftRadius: 10, cursor: 'pointer' }} onClick={profileClicked}>
                    <label style={{ cursor: 'pointer', color: selectedIndex === 1 ? '#FFFFFF' : '#555555', fontSize: 15, fontWeight: '500' }}>Reset Password</label>
                </div>
                <div style={{ backgroundColor: '#555555', height: 0.5 }}></div>
                <div style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 12, paddingBottom: 12, cursor: 'pointer', backgroundColor: selectedIndex === 2 ? '#1d1d5e' : '#FFFFFF' }} onClick={countriesClicked}>
                    <label style={{ cursor: 'pointer', color: selectedIndex === 2 ? '#FFFFFF' : '#555555', fontSize: 15, fontWeight: '500' }}>Countries</label>
                </div>

                <div style={{ backgroundColor: '#555555', height: 0.5 }}></div>
                <div style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 12, paddingBottom: 12, cursor: 'pointer', backgroundColor: selectedIndex === 3 ? '#1d1d5e' : '#FFFFFF' }} onClick={logoutClicked}>
                    <label style={{ cursor: 'pointer', color: selectedIndex === 3 ? '#FFFFFF' : '#555555', fontSize: 15, fontWeight: '500' }}>Logout</label>
                </div>
                <div style={{ backgroundColor: '#555555', height: 0.5 }}></div>

            </div>
            <div style={{ flex: 8, borderTopWidth: 1, borderRightWidth: 1, borderBottomWidth: 1, borderStyle: 'solid', borderColor: '#E5E5E5', borderTopRightRadius: 10, borderBottomRightRadius: 10, display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontSize: 22, marginLeft: 15, marginTop: 10, fontWeight: 'bold', color: '#1d1d5e' }}>{title}</label>
                <div style={{ backgroundColor: '#E5E5E5', height: 1, marginTop: 20 }}></div>
                {
                    selectedIndex == 1 && <div>
                        <div style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 25 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                <label style={{ width: 150 }}>New Password</label>
                                <FormControl name='password' className='rounded-3 inputfocus' placeholder='eg.admin@123' type={showPassword ? 'text' : 'password'} style={{ width: 300 }} onChange={(e) => handlePassword(e)} />
                                <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', position: 'absolute', right: 100, top: '50%', transform: 'translateY(-50%)', color: '#CCCCCC' }}>
                                    {showPassword ? <BsEyeSlashFill size={20} /> : <BsEyeFill size={20} />}
                                </span>
                                {error && !password && (
                                    <p style={{ fontSize: 12, color: 'red', marginInlineEnd: 250 }}>
                                        Please Enter Password.
                                    </p>
                                )}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                <label style={{ width: 150 }}>Confirm Password</label>
                                <FormControl name='confirm password' className='rounded-3 inputfocus' placeholder='eg.admin@123' type={showPassword ? 'text' : 'password'} style={{ width: 300 }} onChange={(e) => handlePassword(e)} />
                                {error && !confirmPassword ? (
                                    <p style={{ fontSize: 12, color: 'red', marginInlineEnd: 200 }}>
                                        Please confirm your password.
                                    </p>
                                ) : error && !passwordMatch ? (
                                    <p style={{ fontSize: 12, color: 'red', marginInlineEnd: 250 }}>
                                        Password do no match.
                                    </p>
                                ) : null}
                            </div>

                            <Button className='mt-4 border-0 fw-bolder' type='submit' style={{ backgroundColor: '#1d1d5e', width: 'max-content', height: 'max-content' }} onClick={handleResetPassword}>Change Password</Button>
                        </div>

                    </div>
                }
                {
                    selectedIndex === 3 && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                        <label style={{ fontSize: 18, fontWeight: '500' }}>Are you sure want to log out?</label>

                        <div style={{ marginTop: 25, display: 'flex' }}>
                            <div style={{ cursor: 'pointer', paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, marginRight: 12, borderWidth: 2, borderColor: '#1d1d5e', borderStyle: 'solid', borderRadius: 5 }} onClick={logout}>
                                <label style={{ cursor: 'pointer', color: '#1d1d5e', fontWeight: 500, fontSize: 13 }}>Logout</label>
                            </div>

                            <div style={{ cursor: 'pointer', paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, marginRight: 12, borderWidth: 2, borderColor: '#1d1d5e', borderStyle: 'solid', borderRadius: 5, backgroundColor: '#1d1d5e' }} onClick={cancel}>
                                <label style={{ cursor: 'pointer', color: '#FFFFFF', fontWeight: 500, fontSize: 13 }}>Cancel</label>
                            </div>
                        </div>
                    </div>
                }
                {
                    selectedIndex === 2 && <div style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 20, }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {
                                props.master.countries.length > 0 && props.master.countries.map((item) => {
                                    return <div style={{ flex: '0 0 33.322%', display: 'flex', justifyContent: 'center', padding: 1, height: 150, backgroundColor: '#EEEEEE' }}>
                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: '#FFFFFF', alignItems: 'center' }}>
                                            <img src={item.countryFlag} style={{ width: 50, height: 50, borderRadius: 25 }} />
                                            <label style={{ marginLeft: 15, fontSize: 18 }}>{item.countryName}</label>
                                        </div>

                                    </div>
                                })
                            }

                            <div>

                            </div>

                        </div>

                        {
                            isAddCountryVisible ? <div style={{ display: 'flex', marginTop: 30, flexDirection: 'column' }}>
                                <label style={{ fontSize: 18, fontWeight: '500' }}>Add new Country</label>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>{
                                    showErrorMessage &&  <div>
                                    <label style={{color: 'red'}}>All fields are mandatory.</label>
                                    </div>
                                   }
                                    <div style={{ display: 'flex', marginTop: 12 }}>
                                        <Form.Control
                                            type="text"
                                            className="inputfocus border-1 rounded-1"
                                            placeholder="Country Name"
                                            style={{ width: 170, height: 30, fontSize: 14 }}
                                            value={countryName}
                                            onChange={(e) =>
                                                countryNameChange(e.target.value)
                                            }
                                        />

                                        <Form.Control
                                            type="text"
                                            className="inputfocus border-1 rounded-1"
                                            placeholder="Country Code"
                                            style={{ width: 170, height: 30, fontSize: 14, marginLeft: 20 }}
                                            value={countryCode}
                                            onChange={(e) =>
                                                countryCodeChange(e.target.value)
                                            }
                                        />

                                        <Form.Control
                                            type="text"
                                            className="inputfocus border-1 rounded-1"
                                            placeholder="Currency Code"
                                            style={{ width: 170, height: 30, fontSize: 14, marginLeft: 20 }}
                                            value={currency}
                                            onChange={(e) =>
                                                currencyChange(e.target.value)
                                            }
                                        />

                                        <Form.Control
                                            type="number"
                                            className="inputfocus border-1 rounded-1"
                                            placeholder="Mobile Code"
                                            style={{ width: 170, height: 30, fontSize: 14, marginLeft: 20 }}
                                            value={mobileCode}
                                            onChange={(e) =>
                                                handleMobileCode(e.target.value)
                                            }
                                        />

                                        <Form.Control
                                            type="number"
                                            className="inputfocus border-1 rounded-1"
                                            placeholder="Mobile Length"
                                            style={{ width: 170, height: 30, fontSize: 14, marginLeft: 20 }}
                                            value={mobileLength}
                                            onChange={(e) =>
                                                handleMobileLength(e.target.value)
                                            }
                                        />


                                    </div>
                                   

                                    {
                                        selectedFiles ? <div>
                                            <div style={{ marginTop: 30 }}>
                                                <img src={image} style={{ height: 80, width: 80 }} />
                                            </div>
                                        </div> : <div style={{ height: 100, paddingLeft: 50, paddingRight: 50, border: '2px dotted #888888', marginTop: 15, display: 'flex' }} {...getRootProps()}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }} >
                                                <input type="file" accept="image/png, image/gif, image/jpeg" {...getInputProps()} />
                                                {
                                                    isDragActive ?
                                                        <p>Drop the file here ...</p> :
                                                        <p>Drag and drop flag file here, or click to select files</p>
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div style={{ display: 'flex' }}>
                                        <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#1d1d5e', borderRadius: 5, marginTop: 20, cursor: 'pointer' }} onClick={uploadCountryDetails}>
                                            <label style={{ color: '#FFFFFF', cursor: 'pointer' }}>Done</label>
                                        </div>
                                    </div>


                                    <div>

                                    </div>
                                </div>
                            </div> : null
                        }
                        {
                            !isAddCountryVisible && <div style={{ display: 'flex' }}>


                                <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#1d1d5e', borderRadius: 5, marginTop: 20, cursor: 'pointer' }} onClick={enableAddCountry}>
                                    <label style={{ color: '#FFFFFF', cursor: 'pointer' }}>Add Country</label>
                                </div>
                            </div>
                        }

                    </div>
                }
            </div>
        </div>

        {loading && (
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100vh",
                    top: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#EEEEEE33",
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <BeatLoader color="#1d1d5e" />
                </div>
            </div>
        )}
    </div>


}

const mapToProps = (state) => {
    return {
        loginUsers: state.users,
        common: state.commonReducer,
        master: state.masterData
    }
}

export default connect(mapToProps)(ProfilePage);